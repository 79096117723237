/**
 * 訪れたページのディレクトリを判定して、対応するヘッダーのメニューに色をつける
 *
 */
export function paint() {
  window.addEventListener('DOMContentLoaded', function() {
    // 対象の最上層のディレクリ名一覧
    const directory = ['company', 'work', 'recruit'];
    // 現在のページのパス
    const pathname = location.pathname;
    // 現在のページと一覧を比べて部分一致するか調べる。
    // 一致すれば、メニューに色をつける。そうでないなら色を外す。
    directory.find(e => {
      const target = document.getElementById(`menu_${e}`);
      if (pathname.includes(e)) {
        target.classList.add('is-current');
      } else {
        target.classList.remove('is-current');
      }
    });
  });
}
