/**
 * aタグをクリックした時のスクロールアニメーション
 */
export function scroll() {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', e => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href');
      let targetElement = document.getElementById(href.replace('#', ''));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const headerHeight = 70;
      const target = rect + offset - headerHeight;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }

  window.onload = function() {
    const url = window.location.hash;
    let targetElement = document.getElementById(url.replace('#', ''));
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const headerHeight = 70;
      const target = rect + offset - headerHeight;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    }
  };
}
