import {
  fadeInLeft,
  fadeInLeftLoad,
  hamburger,
  subOpen,
} from './modules/animation';
import { paint } from './modules/paint';
import { scroll } from './modules/scroll';

fadeInLeft();
fadeInLeftLoad();
hamburger();
subOpen();
scroll();
paint();
