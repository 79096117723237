/**
 * ベタの後に画像が左からフェードインするアニメーション
 */
export function fadeInLeft() {
  window.addEventListener('scroll', function() {
    const heroColor = document.getElementsByClassName('js-anime');
    for (var i = 0; i < heroColor.length; i++) {
      var rect = heroColor[i].getBoundingClientRect();
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var myTop = rect.top + scrollTop;
      var elemPos = myTop - 50;
      var scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight = document.documentElement.clientHeight;
      if (scroll >= elemPos - windowHeight) {
        heroColor[i].classList.add('active');
      } else {
        heroColor[i].classList.remove('active');
      }
    }
  });
}

/**
 * ベタの後に画像が左からフェードインするアニメーション
 */
export function fadeInLeftLoad() {
  window.addEventListener('DOMContentLoaded', function() {
    const heroColor = document.getElementsByClassName('js-anime-load');
    for (var i = 0; i < heroColor.length; i++) {
      heroColor[i].classList.add('active');
    }
  });
}

/**
 * ハンバーガーの入れ子を閉じる
 * 最上層を閉じた時（ばつボタンを押した時）に入れ子も閉じる
 */
function subClose() {
  const open_inner = document.querySelectorAll('.c-hamburgerInner_box.is-open');
  const active_inner = document.querySelectorAll(
    '.c-hamburgerInner_directory.is-active'
  );
  const openList = Array.prototype.slice.call(open_inner, 0);
  openList.forEach(e => {
    e.classList.remove('is-open');
  });
  const activeList = Array.prototype.slice.call(active_inner, 0);
  activeList.forEach(e => {
    e.classList.remove('is-active');
  });
}

/**
 * ハンバーガーメニューの開閉
 */
export function hamburger() {
  // ハンバーガー開閉ボタン
  const button = document.getElementById('hamburger_button');
  // 黒背景
  const background = document.getElementById('hamburger_background');
  // ばつの見た目
  const mark = document.getElementById('hamburger_mark');

  open(button);
  open(background);

  function open(e) {
    // ハンバーガー最上層
    const upper_menu = document.getElementById('upper_menu');
    e.addEventListener('click', function() {
      button.classList.toggle('is-active');
      mark.classList.toggle('is-active');
      if (button.classList.contains('is-active')) {
        upper_menu.classList.add('is-open');
      } else {
        upper_menu.classList.remove('is-open');
        subClose();
      }
    });
  }
}

/**
 * ハンバーガーメニューの入れ子の開閉
 */
export function subOpen() {
  const inner_button = document.querySelectorAll('.c-hamburgerInner_directory');
  const inner_buttonList = Array.prototype.slice.call(inner_button, 0);
  inner_buttonList.forEach(e => {
    e.addEventListener('click', () => {
      if (e.classList.contains('is-active')) {
        // 一旦、全て消す
        inner_buttonList.forEach(e => {
          e.classList.remove('is-active');
          e.nextElementSibling.classList.remove('is-open');
        });
      } else {
        // 一旦、全て消す
        inner_buttonList.forEach(e => {
          e.classList.remove('is-active');
          e.nextElementSibling.classList.remove('is-open');
        });
        e.classList.add('is-active');
        e.nextElementSibling.classList.add('is-open');
      }
    });
  });
}
